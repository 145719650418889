import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
    container: {
        paddingLeft: 40,
        paddingRight: 0,
        paddingTop: 10,
    },
    containerQcasts:{
        display: "flex",
        justifyContent: "center",
        marginTop: 30,
        marginBottom: 55,
        marginLeft: "auto",
        marginRight: "auto",
    },
    containerQcastsButtonMore: {
        width: "100%",
        height: 40,
    },
    containerQcast: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 400,
        width: "100%",
        padding: 0,
        fontFamily: "Poppins",
        border: "none",
        borderRadius: 10,
        marginBottom: 20,
    },


    qcastCtrlBtn1: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginTop: 15,
    },

    qcastCtrlBtn2: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginTop: 15,
    },
    qcastCtrlBtn3: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginTop: 15,
    },

    txtQcastTime: {
        color: "#6497C7",
        fontWeight: "bold"
    },
    imgPopularLarge1: {
        height: "100%",
        borderRadius: "100%",
        objectFit: "cover",
        background:"rgba(0,0,0,.3)",
        border: "16px solid #ECF5FC",
        borderTopColor: "#a0c8e7",
        transform: "rotate(45deg)",
        boxShadow: "-3px -3px 10px #FFFFFF, 3px 4px 10px rgba(0, 0, 0, 0.25)",
    },
    imgPopularLarge2: {
        height: "100%",
        borderRadius: "100%",
        objectFit: "cover",
        transform: "rotate(-45deg)",
        background:"rgba(0,0,0,.3)",
        boxShadow: "-3px -3px 10px #FFFFFF, 3px 4px 10px rgba(0, 0, 0, 0.25)",
    },
    qcastSWBtn: {
        display: "flex",
        width: 70,
        height: 40,
        alignItems: "center",
        justifyContent: "space-around",
        background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
        boxShadow: "rgb(255 255 255 / 70%) 1px -3px 10px, rgb(0 0 0 / 18%) 5px 3px 10px",
        border: "none",
        borderRadius: 100,
    },
    qcastSWBtnActive: {
        display: "flex",
        width: 70,
        height: 40,
        alignItems: "center",
        justifyContent: "space-around",
        background: "#EFF6FC",
        boxShadow: "inset -2px -4px 5px rgba(255, 255, 255, 0.7), inset 2px 4px 5px rgba(0, 0, 0, 0.25);",
        border: "none",
        borderRadius: 100,
    },
    qcastImgBtn: {
        display: "flex",
        width: 40,
        height: 40,
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
        boxShadow: "rgb(255 255 255 / 70%) 1px -3px 10px, rgb(0 0 0 / 18%) 5px 3px 10px",
        border: "none",
        borderRadius: 100,
    },

    editPlaylist: {
        display: "flex",
        height: 55,
        color: "#7B94B5",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
        boxShadow: "rgb(255 255 255 / 70%) 1px -3px 10px, rgb(0 0 0 / 18%) 5px 3px 10px",
        border: "none",
        borderRadius: 100,
        minWidth: 350,
    },

    editPlaylistBtnActive: {
        display: "flex",
        height: 55,
        color: "#7B94B5",
        fontSize: 18,
        alignItems: "center",
        justifyContent: "center",
        background: "#EFF6FC",
        boxShadow: "inset -2px -4px 5px rgba(255, 255, 255, 0.7), inset 2px 4px 5px rgba(0, 0, 0, 0.25);",
        border: "none",
        borderRadius: 100,
        minWidth: 350,
    },
    qcastImgBtnActive: {
        display: "flex",
        height: 40,
        width: 40,
        alignItems: "center",
        justifyContent: "center",
        background: "#EFF6FC",
        boxShadow: "inset -2px -4px 5px rgba(255, 255, 255, 0.7), inset 2px 4px 5px rgba(0, 0, 0, 0.25);",
        border: "none",
        borderRadius: 100,
    },
    qcastPlayBtnDiv: {
        display: "flex",
        width: 78,
        height: 78,
        alignItems: "center",
        justifyContent: "center",
        background: "#F1F9FF",
        boxShadow: "rgb(255 255 255 / 70%) 2px -1px 4px, rgb(0 0 0 / 18%) -2px -1px 8px",
        border: "none",
        borderRadius: 100,
    },
    qcastPlayBtn: {
        display: "flex",
        width: 52,
        height: 52,
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
        boxShadow: "rgb(255 255 255 / 70%) 1px -3px 10px, rgb(0 0 0 / 18%) 5px 3px 10px",
        border: "none",
        borderRadius: 100,
    },
    qcastPlayBtnActive: {
        display: "flex",
        width: 52,
        height: 52,
        alignItems: "center",
        justifyContent: "center",
        background: "#EFF6FC",
        boxShadow: "inset -2px -4px 5px rgba(255, 255, 255, 0.7), inset 2px 4px 5px rgba(0, 0, 0, 0.25);",
        border: "none",
        borderRadius: 100,
    },
    imgQcast: {
        display: "flex",
    },
    img_20: {
        width: 20,
    },
    img_30: {
        width: 30,
    },
    img_35: {
        width: 35,
    },
    txtResContentIcon: {
        color: "#97AAC3",
        fontSize: 14,
        fontWeight: "500",
        lineHeight: "24px",
        marginBottom: 0,
        marginRight: 35,
    },
    qcastReviewTexts: {
        position: "absolute",
        right: 10,
        maxWidth: 500,
        width: "100%",
        padding: 10,
        fontFamily: "Poppins",
        height: 700,
        overflow: "auto",
        marginLeft: 50
    },
    qcastReviewText: {
        display: "flex",
        background: "#EFF6FC",
    },
    qcastReviewContentNum: {
        padding: 10,
    },
    qcastReviewContent: {
        display: "flex",
        background: "#EFF6FC",
        boxShadow: "inset -2px -4px 5px rgba(255, 255, 255, 0.7), inset 2px 4px 5px rgba(0, 0, 0, 0.25);",
        border: "none",
        borderRadius: 10,
        fontSize: 14,
        padding: 16,
        marginBottom: 20
    },
})
