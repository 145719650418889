// @flow
import { StyleSheet } from "aphrodite";
import { Colors } from "../../theme";

export default StyleSheet.create({
  container: {
    // width: "285px",
    // height:"1500px",
    display: "fixed",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 32,
    zIndex: 998,
    boxShadow:
      "-1px -3px 10px rgba(255, 255, 255, 0.7), 5px 3px 10px rgba(0, 0, 0, 0.18)",
    background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
    fontSize: 18,
    color: "#7B94B5",
    fontFamily: "Poppins",
  },

  containerLoc: {
    margin: 0,
  },

  navigationButton: {
    display: "flex",
    // justifyContent: "center",
    listStyle: "none",
    paddingLeft: 0,
    paddingRight: 0,
    // paddingTop: 6,
    // paddingBottom: 6,
  },
  linkButton: {
    display: "block",
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 20,
    paddingRight: 20,
    color: "#7B94B5",
    textDecoration: "none",
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: "500",
    lineHeight: "27px",
    "@media (min-width:1200px)": {
      paddingLeft: 20,
      paddingRight: 20,
    }
  },
  linkButtonActive: {
    display: "block",
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 20,
    paddingRight: 20,
    color: "#6497C7",
    textDecoration: "none",
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: "500",
    lineHeight: "27px",
    background: "#EFF6FC",
    boxShadow:
      "inset -2px -4px 5px rgba(255, 255, 255, 0.7), inset 2px 4px 5px rgba(0, 0, 0, 0.25);",
    borderRadius: 100,
    "@media (min-width:1200px)": {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  menuContent: {
    paddingLeft: 40,
    // paddingRight: 40,
    marginBottom: 48,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "auto"
  },

  menuLine: {
    border: "1px solid #97AAC3",
  },

  menuTitleSecond: {
    color: "#2C3037",
    fontSize: 18,
    fontWeight: "500",
    lineHeight: "27px",
    marginBottom: 13,
    paddingLeft: 20,
  },

  navigationButtonText: {
    display: "block",
    textAlign: "center",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 33,
    paddingRight: 33,
    color: "#7B94B5",
    textDecoration: "none",
    fontSize: 18,
    "@media (min-width:992px)": {
      paddingLeft: 33,
      paddingRight: 33,
    },
  },
  activeClass: {
    background: "#EFF6FC",
    color: "#6497C7",
    textDecoration: "none",
    boxShadow:
      "inset -2px -4px 5px rgba(255, 255, 255, 0.7), inset 2px 4px 5px rgba(0, 0, 0, 0.25);",
    textAlign: "center",
    borderRadius: 100,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 33,
    paddingRight: 33,
    "@media (max-width:980px)": {
      display: "block",
      paddingLeft: 33,
      paddingRight: 33,
    },
  },

  // accordion
  navButtonParent: {
    listStyle: "none",
  },
  navButtonParent1: {
    display: "flex",
    minWidth: "244px",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  navButtonParent2: {
  },
  acordMenuContent: {
    paddingLeft: 10,
    paddingRight: 20,
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
  },
  img_35: {
    width: 35,
  },
  img_35_p_7: {
    width: 35,
    padding: 7
  },
  img_20_p_4: {
    width: 20,
    padding: 4
  },
  img_clear: {
    width: 20,
    padding: 4,
    transform: "rotate(45deg)",
    boxShadow:
      "-1px -3px 10px rgba(255, 255, 255, 0.7), 5px 3px 10px rgba(0, 0, 0, 0.18)",
    borderRadius: 10,
  },
  arrow_down: {
    width: 18,
    height: 10,
    marginRight: 20,
  },

  // messenger
  siderContainer: {
    width: 400,
  },
  searchContainer: {
    paddingLeft: 16,
    paddingRight: 20,
  },
  newMessage: {
    display: "flex",
    padding: 5,
    marginLeft: 14,
    marginBottom: 20,
    width: 320,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
    boxShadow: "rgb(255 255 255 / 70%) 1px -3px 10px, rgb(0 0 0 / 18%) 5px 3px 10px",
    border: "none",
    borderRadius: 20,
    cursor: "pointer",
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",
    color: "#7B94B5",
    fontStyle: "Medium"
  },
  searchBar1: {
    display: "flex",
    width: 334,
    alignItems: "center",
    justifyContent: "center",
  },
  backBtn: {
    display: "flex",
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
    boxShadow: "rgb(255 255 255 / 70%) 1px -3px 10px, rgb(0 0 0 / 18%) 5px 3px 10px",
    border: "none",
    borderRadius: 100,
    marginRight: 10,
    marginBottom: 26,
  },
  seachBar: {
    position: "relative",
    paddingLeft: 5,
    paddingRight: 5,
    marginBottom: 20,
    marginRight: "-20px"
  },
  seachBox: {
    width: "100%",
    height: 50,
    background: "Linear-gradient(171.89deg, #F0F6FB 2.42%, #E3F1FE 96.87%);",
    boxShadow: "inset -2px -5px 7px rgba(255, 255, 255, 0.7), inset 2px 5px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    border: "none",
    padding: 10,
    fontSize: 14,
    lineHeight: "21px",
    fontFamily: "Poppins",
    color: "#7B94B5",
    fontStyle: "Regular"
  },
  seachBox1: {
    width: 270,
    height: 50,
    background: "Linear-gradient(171.89deg, #F0F6FB 2.42%, #E3F1FE 96.87%);",
    boxShadow: "inset -2px -5px 7px rgba(255, 255, 255, 0.7), inset 2px 5px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    border: "none",
    padding: 10,
    fontSize: 14,
    lineHeight: "21px",
    fontFamily: "Poppins",
    color: "#7B94B5",
    fontStyle: "Regular"
  },
  searchLabel: {
    paddingLeft: 5,
    fontWeight: "400",
    fontSize: 14,
    lineHeight: "21px",
    fontFamily: "Poppins",
    color: "#7B94B5",
    fontStyle: "normal",
    marginBottom: 5,
    marginLeft: 40
  },
  closeBtn: {
    position: "absolute",
    top: 8,
    right: 0,
    display: "flex",
    width: 30,
    height: 30,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
    boxShadow: "rgb(255 255 255 / 70%) 1px -3px 10px, rgb(0 0 0 / 18%) 5px 3px 10px",
    border: "none",
    borderRadius: 30,
    cursor: "pointer"
  },
  clearBtn: {
    position: "absolute",
    top: 8,
    right: 16,
    display: "flex",
    width: 26,
    height: 26,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
    boxShadow: "rgb(255 255 255 / 70%) 1px -3px 10px, rgb(0 0 0 / 18%) 5px 3px 10px",
    border: "none",
    borderRadius: 30,
    cursor: "pointer"
  },
  img_24: {
    width: 24,
  },
  hr: {
    marginTop: 0,
    marginBottom: 0,
  },
  qcastList: {
    // padding: 20,
    // height: 600,
  },
  qcastItem: {
    display: "flex",
    columnGap: 5,
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  qcastItemActive: {
    display: "flex",
    columnGap: 5,
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    background: "#97AAC333"
  },
  imgPopularSmall: {
    height: 42,
    borderRadius: "100%",
    objectFit: "cover",
    border: "3px solid #ECF5FC",
    boxShadow: "-3px -3px 10px #FFFFFF, 3px 4px 10px rgba(0, 0, 0, 0.25)",
  },
  qcastTitle: {
    paddingLeft: 5,
    fontWeight: "600",
    fontSize: 18,
    lineHeight: "27px",
    fontFamily: "Poppins",
    color: "#515A6B",
    fontStyle: "SemiBold",
    marginBottom: "auto",
    marginTop: "auto"
  },
  userMsgInfo: {
    // display: "flex",
    // flexDirection: "column",
    alignItems: "center",
  },
  userMsgInfo1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  msgTime: {
    fontWeight: "400",
    fontSize: 12,
    lineHeight: "18px",
    fontFamily: "Poppins",
    color: "#6497C7",
    fontStyle: "normal",
    marginBottom: "auto",
    marginTop: "auto"
  },
  msgContent: {
    paddingLeft: 5,
    fontWeight: "400",
    fontSize: 14,
    lineHeight: "21px",
    fontFamily: "Poppins",
    color: "#7B94B5",
    fontStyle: "normal",
    marginBottom: "auto",
    marginTop: "auto"
  },
  msgNum: {
    display: "flex",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: "18px",
    fontFamily: "Poppins",
    color: "#6497C7",
    fontStyle: "normal",
    marginBottom: "auto",
    marginTop: "auto",
    padding: 5,
    marginLeft: 20,
    width: 22,
    height: 22,
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
    boxShadow: "rgb(255 255 255 / 70%) 1px -3px 10px, rgb(0 0 0 / 18%) 5px 3px 10px",
    border: "none",
    borderRadius: "100%",
  },
  msgAction: {
    display: "flex",
    width: 290,
    alignItems: "center",
    justifyContent: "space-between",
  },
  forwardBtn: {
    display: "flex",
    padding: 5,
    marginLeft: 20,
    width: 100,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
    boxShadow: "rgb(255 255 255 / 70%) 1px -3px 10px, rgb(0 0 0 / 18%) 5px 3px 10px",
    border: "none",
    borderRadius: 10,
    cursor: "pointer",
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",
    color: "#7B94B5",
    fontStyle: "Medium"
  },
  containerNoResult: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  txtNoResultTitle: {
    fontSize: 24,
    fontWeight: "SemiBold",
    lineHeight: "36px",
    marginTop: 23,
    marginBottom: 0,
    color: "#7B94B5",
  },
  txtNoResultContents: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  txtNoResultContent: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "27px",
    marginTop: 5,
    marginBottom: 0,
    color: "#7B94B5",
  },
  txtNoResultBack: {
    marginLeft: 5,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "27px",
    marginTop: 5,
    marginBottom: 0,
    color: "#7B94B5",
    cursor: "pointer"
  },
  img_noResult: {
    height: 200,
  },
});
